import {
    getHomeGoods,
} from '@/utils/home.js';
export default {
    name: "memberInterest",
    data() {
        return {
            status: -1,
            goodsList: [],
            goodsList1: [],
        }
    },
    created() {
        // 种草商品
        // this.getHomeGoodsFunc(3);
        // 普通商品
        // this.getHomeGoodsFunc(0);
    },
    methods: {
        // 获取商品列表
        getHomeGoodsFunc(channel) {
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodName: '',
                sortMethod: 1,
                pageNo: 1,
                pageSize: 4,
                channel: channel, //(0=普通专区,1=限时积分专区,2=达人专区,3非达人种草商品)
                categoryFirstId: '',
                tag: ''
            }
            getHomeGoods(data).then(res => {
                console.log('getHomeGoods', res)
                if (res.result) {
                    let arr = res.result.goodList;
                    if (channel == 3) {
                        this.goodsList = arr;
                    } else {
                        this.goodsList1 = arr;
                    }
                }
            })
        },
        // 跳转商品详情
        gotoPagesFunc(id) {
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    goodsId: id
                }
            })
        },
        // 跳转到达人专区
        goDapersonFunc() {
            this.$router.push({
                path: '/daPerson'
            })
        }
    }
}