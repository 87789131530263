<template>
  <div class="apply-member">
    <img src="@/assets/img/applyMembers/haowu.png" class="four" />
    <div :class="['tilte flex align-center flex-center', {'status-two': status == 1 || status == 2 }]">
      <img src="@/assets/img/applyMembers/left.png" class="left" />
      <p>达人权益介绍</p>
      <img src="@/assets/img/applyMembers/right-title.png" class="right" />
    </div>
    <div class="a-box">
      <p class="a-title">权益1: 达人专享品牌商品优惠价</p>
      <div class="hold" style="display:none;">
        <div class="t-h flex">
          <p>168件品牌商品</p>
          <img src="@/assets/img/applyMembers/11.png" alt="" />
        </div>
        <div class="s-h flex">
          <div class="s-li" v-for="item in goodsList" :key="item.id" @click="gotoPagesFunc(item.goodId)">
            <div class="s-li-img" :style="{background:'url('+item.goodPic+')no-repeat center center',backgroundSize: 'cover'}"></div>
            <p class="t-t">{{item.goodName}}</p>
            <!-- <p class="t-d">30ml</p> -->
            <p class="b-b flex align-end">
              低至<span class="l-s"><i>¥</i>{{item.price}}</span
              ><span class="r-s">/件</span>
            </p>
          </div>
        </div>
      </div>
      <div class="hold1">
        <img src="http://sfwx.cn-sh2.ufileos.com/banner/banner1.png" alt="">
      </div>
    </div>
    <div class="a-box second-box">
      <p class="a-title">权益2: 达人推广灵龙惠生活商品 得奖励</p>
      <div class="hold" style="display:none;">
        <div class="t-h flex">
          <p style="color: #ac6a1a">全平台商品均有</p>
          <img src="@/assets/img/applyMembers/10.png" style="margin-top: 3px" />
        </div>
        <div class="s-h flex">
          <div class="s-li" v-for="item in goodsList1" :key="item.id" @click="gotoPagesFunc(item.goodId)">
            <div class="s-li-img" :style="{background:'url('+item.goodPic+')no-repeat center center',backgroundSize: 'cover'}"></div>
            <p class="t-t">{{item.goodName}}</p>
            <!-- <p class="t-d">30ml</p> -->
            <p class="b-b flex align-end">
              佣金<span class="l-s"><i>¥</i>{{parseFloat(item.returnAmount/100).toFixed(2)}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="hold1">
        <img src="http://sfwx.cn-sh2.ufileos.com/banner/banner2.png" alt="">
      </div>
    </div>
    <div class="a-box">
      <p class="a-title">权益3: 推荐身边好友成为达人，得推荐奖</p>
      <div class="s-hold flex">
        <div class="s-h-l">
          <div class="one flex align-center">
            <p>推荐</p>
            <img src="@/assets/img/applyMembers/one.png" alt="" />
            <p>好友成为灵龙种草达人</p>
          </div>
          <img src="@/assets/img/applyMembers/9.png" class="center">
          <p class="bottom">追求极简生活、追求性价比的<br>生活理念 传播给身边人</p>
        </div>
        <div class="s-h-r">
          <img src="@/assets/img/applyMembers/three.png" class="s-h-r-img" />
          <div class="s-h-r-price">50<span>元</span></div>
        </div>
      </div>
    </div>
    <div :class="['tilte flex align-center flex-center', {'status-two': status == 1 || status == 2 }]">
      <img src="@/assets/img/applyMembers/left.png" class="left" />
      <p class="p1">如何成为灵龙种草达人</p>
      <img src="@/assets/img/applyMembers/right-title.png" class="right" />
    </div>
    <div class="a-box apply-member-content">
      <div class="a-box-t">
        <p class="a-box-t-p">1.首先用户必须注册为灵龙惠生活的会员等级1以及以上等级的会员(只要注册，即可成为等级1的灵龙会员)</p>
        <p class="a-box-t-p">2.查看并同意《升级成为灵龙种草达人协议》和《灵龙惠生活会员和人推广规范》</p>
        <p class="a-box-t-p">3.在灵龙惠生活种草商品”区选择种草商品成功购买、确认收货，完成订单后，即可自动成为灵龙种草达人。</p>
        <p class="a-box-t-p a-box-t-p2">4.在种草商品区的商品和其他区域商品交易规则不同：</p>
        <p class="a-box-t-p1">
          (1)购买种草商品时无法用龙珠和优惠券抵扣；<br/>
          (2)购买成功后不会赠送龙珠；<br/>
          (3)会员购买种草商品，不计算在给予推荐达人的推广返佣中。
        </p>
        <p class="a-box-t-p">5.购买种草商品时相同商品可以叠加购买，但每次购买只能选1种品。</p>
      </div>
      <div class="flex flex-left a-box-b">
        <img class="a-box-b-img" src="../../assets/img/applyMembers/warning.png" alt="">
        <p class="a-box-b-p">
          <span>注意购买种草商品后如申请售后(退款退货、仅退款)成功后，则天法升级至种草达人。</span>
        </p>
      </div>
      <div class="a-box-fix" :style="{background: 'url('+require('../../assets/img/applyMembers/title1.png')+')no-repeat center center',backgroundSize: 'cover'}"></div>
    </div>
    <div :class="['tilte flex align-center flex-center', {'status-two': status == 1 || status == 2 }]">
      <img src="@/assets/img/applyMembers/left.png" class="left" />
      <p class="p1">成为种草达人，可得如下权益</p>
      <img src="@/assets/img/applyMembers/right-title.png" class="right" />
    </div>
    <div class="a-box apply-member-content">
      <div class="a-box-t">
        <p class="a-box-t-p">1.达人无需围货、无需发货、一键分享，有下单就能赚钱，平台提供完整的购买服务体系，支持一件代发快递和批发货运。</p>
        <p class="a-box-t-p">2.种草身边人性价比好物，朋友拔草会给达人对应可提现奖励，无人来拔草则0损失。</p>
        <p class="a-box-t-p">3.自购返龙珠，达人比普通会员约高出20%~80%的龙珠奖励。</p>
        <p class="a-box-t-p">4.精美素材便捷发布所有商品素材专业的小编提前准备，直接转发商品，轻松推广。</p>
        <p class="a-box-t-p">
          5.实时激励随时提现:收益实时展示，佣金提现方便，5个工作日内到账。
        </p>
        <p class="a-box-t-p">6.在微信中直接交易，支持微信支付，无需下载任何APP。</p>
      </div>
      <div class="a-box-fix" :style="{background: 'url('+require('../../assets/img/applyMembers/title2.png')+')no-repeat center center',backgroundSize: 'cover'}"></div>
    </div>
    <div class="a-toast">达人权益和享受优惠的商品最终解释权归平台所有</div>
    <div class="flex flex-center align-center a-btn" @click="goDapersonFunc">
      立即成为 灵龙种草达人
    </div>
    <!-- <div class="a-box four-box">
      <p class="a-title">定期可免费参加平台基础新媒体培训课程</p>
      <div class="t-hold">
          <div class="t-top flex flex-center">
              <img src="@/assets/img/applyMembers/eight.png" alt="">
              <p>学习了解各种其他渠道做传播、带货的基础知识</p>
              <img src="@/assets/img/applyMembers/eight.png" alt="">
          </div>
          <div class="t-ul flex flex-between">
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/six.png" alt="">
                  <p>抖音</p>
              </div>
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/five.png" alt="">
                  <p>快手</p>
              </div>
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/seven.png" alt="">
                  <p>小红书</p>
              </div>
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/two.png" alt="">
                  <p>微信视频号</p>
              </div>
          </div>
          <p class="s-b-b">价值<i>9999元</i>的课程～达人<span>免费尊享</span></p>
      </div>
    </div> -->
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>